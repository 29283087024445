<script lang="ts" setup>
import { computed, ref, watchEffect } from 'vue'
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core'
import { IconArrowRight } from '@cypress-design/vue-icon'
import ProductDescriptionListBullet from './ProductDescriptionListBullet.vue'
import ProductDescriptionListDashes from './ProductDescriptionListDashes.vue'
import DescriptionItem, { type ProductDescriptionItemContent, type ProductId } from './DescriptionItems'

export interface ProductDescriptionListItem {
  title: string
  paragraph: string
  richText?: boolean
  learnMoreLink?: {
    href: string
    text?: string
    target?: string
  }
}

const { lg } = useBreakpoints(breakpointsTailwind)

const props = defineProps<{
  items: (ProductDescriptionListItem & ProductDescriptionItemContent)[]
  openIndex: number
  shouldAnimate: boolean
  currentVideoDuration: number
  productId: ProductId
}>()

interface Item {
  playMobile?: () => void
}

const $list = ref<HTMLUListElement>()
const listHeight = 450
const $items = ref<Item[]>([])

const closedSectionHeight = 42

const sectionGap = 24

const paragraphHeight = computed(() => {
  return listHeight - (props.items.length - 1) * closedSectionHeight - (props.items.length - 1) * sectionGap
})

function playItems(items: Item[]) {
  items.forEach((item) => {
    item.playMobile?.()
  })
}

watchEffect(() => {
  if (!lg.value) {
    playItems($items.value)
  }
})

const restingHeight = computed(() => {
  if (import.meta.env.SSR) {
    return undefined
  }

  if (lg.value) {
    return 0
  } else {
    return 'auto'
  }
})

const clampedOpenIndex = computed(() => {
  return Math.max(Math.min(props.openIndex, props.items.length), 0)
})

defineEmits<{
  (event: 'update:openIndex', index: number): void
}>()
</script>

<template>
  <div class="relative">
    <ProductDescriptionListDashes class="absolute bottom-[8px] top-[32px] z-20" />
    <ul ref="$list" class="relative flex h-full flex-col gap-[24px]">
      <li
        v-for="({ title, paragraph, learnMoreLink, ...item }, index) in items"
        :key="title"
        class="relative z-20 block pl-[16px] sm:pl-[32px]"
        :class="{
          'lg:grow': index === clampedOpenIndex,
        }"
      >
        <div
          class="duration-1500 absolute left-[-1px] top-[28px] z-20 w-[2px] origin-top transform bg-indigo-500 lg:transition-none"
          :class="{
            'scale-y-0': index > openIndex,
            'scale-y-100 transition-transform': index <= openIndex,
            'h-full': index < items.length - 1,
            'bottom-0': index === items.length - 1, // set the last item to be a little shorter since it does not have a bullet
          }"
          :style="
            shouldAnimate && index === openIndex ? `animation: grow ${currentVideoDuration}ms linear forwards` : ''
          "
        />
        <div class="group">
          <div class="absolute left-[-3px] top-[2px] z-20 bg-white py-[8px]">
            <ProductDescriptionListBullet
              :active="index <= openIndex"
              :current="index === openIndex"
              @scroll-passed-threshold="lg ? undefined : $emit('update:openIndex', index)"
            />
          </div>
          <button
            class="m-0 cursor-pointer bg-white text-left font-primary text-[20px] font-semibold leading-[32px]"
            :class="index === openIndex ? 'text-indigo-500' : 'text-gray-1000 group-hover:text-indigo-500'"
            @click="lg ? $emit('update:openIndex', index) : undefined"
          >
            {{ title }}
          </button>
        </div>
        <div
          class="text-gray-700 lg:overflow-hidden lg:transition-all lg:duration-500"
          :class="{
            'lg:h-0': index !== clampedOpenIndex,
          }"
          :style="{
            height: lg && index === openIndex ? `${paragraphHeight}px` : restingHeight,
          }"
        >
          <p class="mt-[8px] text-[16px] leading-[24px] text-gray-700">
            {{ paragraph }}
          </p>
          <DescriptionItem
            ref="$items"
            class="my-[16px] max-w-[calc(100vw-56px)] shadow-asset sm:max-w-[calc(100vw-96px)] md:overflow-hidden lg:hidden"
            :item="item"
            :product-id="productId"
          />
          <a
            v-if="learnMoreLink"
            class="mt-[12px] flex items-center gap-[8px] text-[16px] font-medium leading-[24px] text-indigo-500"
            v-bind="learnMoreLink"
          >
            Learn more<IconArrowRight />
          </a>
        </div>
      </li>
    </ul>
  </div>
</template>

<style>
@keyframes grow {
  0% {
    transform-origin: top;
    transform: scale3d(1, 0, 1);
  }
  100% {
    transform-origin: top;
    transform: scale3d(1, 1, 1);
  }
}
</style>
